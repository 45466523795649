<template>
  <section>
    <section>
      <p class="order-title">订单信息</p>

      <a-row type="flex" :gutter="20" class="m-t-16">
        <a-col :span="6">
          <span style="color: #fff" class="m-r-18">供应商:</span>
          <span style="color: #93BBF3">{{ orderInfo.supplier }}</span>
        </a-col>
        <a-col :span="6">
          <span style="color: #fff" class="m-r-18">单据时间:</span>
          <span style="color: #93BBF3">{{ orderInfo.orderDate }}</span>
        </a-col>
        <a-col :span="6">
          <span style="color: #fff" class="m-r-18">单据编号:</span>
          <span style="color: #93BBF3">{{ orderInfo.code }}</span>
        </a-col>
        <a-col :span="6">
          <span style="color:#fff" class="m-r-18">制单人:</span>
          <span style="color:#93BBF3">{{ orderInfo.operator }}</span>
        </a-col>
      </a-row>
    </section>
    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="orderdataSource"
        :pagination="false"
        size="small"
        class="m-t-18"
        :columns="ordercolumns"
      >
      </a-table>
      <a-row class="ordertotalNum" type="flex" justify="start">
        <span>合计</span>

        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span>{{ stockQty }}</span>
        <span>{{ orderQty }}</span>
      </a-row>
    </section>
    <section class="textarea">
      <a-textarea
        :rows="4"
        :value="orderInfo.remarks"
        placeholder="暂无备注信息"
      ></a-textarea>
    </section>
    <!-- default-active-key="1" -->
    <a-collapse :bordered="false" class="m-t-18">
      <template #expandIcon="props">
        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
      </template>
      <a-collapse-panel
        :header="'材料进场批次-' + item.code"
        :style="customStyle"
        v-for="(item, index) in batchList"
        :key="index"
      >
        <a-row type="flex" :gutter="20" class="m-t-16">
          <a-col :span="6">
            <span style="color: #fff" class="m-r-18">批次编号:</span>
            <span style="color: #93BBF3">{{ item.code }}</span>
          </a-col>
          <a-col :span="6">
            <span style="color: #fff" class="m-r-18">供应商:</span>
            <span style="color: #93BBF3">{{ item.supplier }}</span>
          </a-col>
          <a-col :span="6">
            <span style="color: #fff" class="m-r-18">到达时间:</span>
            <span style="color: #93BBF3">{{ item.exceptedTime }}</span>
          </a-col>
          <!-- <a-col :span="6">
            <span style="color: #fff" class="m-r-18">实际达到时间:</span>
            <span style="color: #93BBF3">{{ item.update_time }}</span>
          </a-col> -->
           <a-col :span="6">
            <span style="color: #fff" class="m-r-18">车牌号:</span>
            <span style="color: #93BBF3">{{ item.carNumber }}</span>
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="20" class="m-t-16">
          <!-- <a-col :span="6">
            <span style="color: #fff" class="m-r-18">车牌号:</span>
            <span style="color: #93BBF3">{{ item.carNumber }}</span>
          </a-col> -->
          <a-col :span="6">
            <span style="color: #fff" class="m-r-18">送货人:</span>
            <span style="color: #93BBF3">{{ item.deliveryman }}</span>
          </a-col>
          <a-col :span="6">
            <span style="color: #fff" class="m-r-18">联系方式:</span>
            <span style="color: #93BBF3">{{ item.phone }}</span>
          </a-col>
          <a-col :span="6">
            <span style="color: #fff" class="m-r-18">状态:</span>
            <span style="color: #93BBF3">{{
              item.status == "0" ? "未入库" : "入库"
            }}</span>
          </a-col>
        </a-row>
        <section class="list-table-container list-table">
          <a-table
            :bordered="false"
            :data-source="item.detailList"
            :pagination="false"
            size="small"
            class="m-t-18"
            :columns="columns"
          >
          </a-table>

          <a-row class="totalNum" type="flex" justify="start">
            <span>合计</span>

            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span>{{ item.total }}</span>
          </a-row>
        </section>
      </a-collapse-panel>
    </a-collapse>

    <!-- <section class="form-container">
      <a-row class="form-btn-group">
        <a-button class="btn-save"><a-icon type="save" />保存</a-button>
        <a-button class="btn-cancel">取消</a-button>
      </a-row>
    </section> -->
  </section>
</template>
<script>
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      text: `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`,
      customStyle:
        "background: #f7f7f7;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden",
      columns: [
        { title: "序号", dataIndex: "key", align: "center", width: 200 },
        {
          title: "材料名称",
          dataIndex: "materialName",
          align: "center",
          width: 200,
        },
        { title: "品牌", dataIndex: "brand", align: "center", width: 200 },
        {
          title: "规格型号",
          dataIndex: "type",
          align: "center",
          width: 200,
        },
        {
          title: "计量单位",
          dataIndex: "unit",
          align: "center",
          width: 200,
        },
        {
          title: "送货数量",
          dataIndex: "batchQty",
          align: "center",
          width: 200,
        },
        {
          title: "入库数量",
          dataIndex: "receivedQty",
          align: "center",
          width: 200,
        },
      ],
      ordercolumns: [
        { title: "序号", dataIndex: "key", align: "center", width: 120 },
        {
          title: "材料名称",
          dataIndex: "materialName",
          align: "center",
          width: 120,
        },
        { title: "品牌", dataIndex: "brand", align: "center", width: 120 },
        {
          title: "规格型号",
          dataIndex: "type",
          align: "center",
          width: 120,
        },
        {
          title: "计量单位",
          dataIndex: "unit",
          align: "center",
          width: 120,
        },
        {
          title: "剩余库存数量",
          dataIndex: "stockQty",
          align: "center",
          width: 120,
        },
        {
          title: "采购数量",
          dataIndex: "orderQty",
          align: "center",
          width: 120,
        },
        {
          title: "采购单价(元)",
          dataIndex: "actualUnitPrice",
          align: "center",
          width: 120,
        },
        {
          title: "折扣率%",
          dataIndex: "discount",
          align: "center",
          width: 120,
        },
        {
          title: "采购金额(元)",
          dataIndex: "oriAmount",
          align: "center",
          width: 120,
        },
        {
          title: "实采金额(元)",
          dataIndex: "actualAmount",
          align: "center",
          width: 120,
        },
      ],
      dataSource: [
        {
          key: "0",
          ind: "0",
          name: "螺纹钢筋螺纹钢筋",
          ind1: "桂万钢",
          ind2: "Φ25",
          ind3: "吨",
          ind4: "23",
          ind5: "桂万钢",
          ind6: "Φ25",
          ind7: "吨",
          ind8: "23",
          ind9: "23",
        },
      ],
      orderdataSource: [],
      orderInfo: [],
      batchList: [],
    };
  },
  created() {
    this.getOrderDetail();
  },
  computed: {
    // 剩余库存数量
    stockQty() {
      let d = 0;
      if (this.orderdataSource) {
        this.orderdataSource.forEach((item) => {
          d = parseInt(item.stockQty) + d;
        });
      }

      return d;
    },
    // 采购数量
    orderQty() {
      let d = 0;
      if (this.orderdataSource) {
        this.orderdataSource.forEach((item) => {
          d = parseInt(item.orderQty) + d;
        });
      }

      return d;
    },
  },
  methods: {
    getOrderDetail() {
      let id = this.$route.query.id;
      this.$api.getOrderDetail(id).then((res) => {
        if (res.code == "200") {
          if (res.data.orderInfo) {
            this.orderInfo = res.data.orderInfo;
            if (
              this.orderInfo.detailList &&
              this.orderInfo.detailList.length > 0
            ) {
              this.orderInfo.detailList.forEach((item, index) => {
                item.key = index + 1;
              });
            }
            this.orderdataSource = this.orderInfo.detailList;
          }

          if (res.data.batchList) {
            res.data.batchList.forEach((item) => {
              var total = 0;
              if(item.detailList&&item.detailList.length>0){
                item.detailList.forEach((items, index) => {
                total = parseInt(items.batchQty) + total;
                items.key = index + 1;
              });
              item.total = total;
              }
              
            });
            this.batchList = res.data.batchList;
            // console.log(this.batchList);
          }
        }
      });
    },
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.form.getFieldsValue();
      console.log(formVal);
    },
    onChange() {},
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100%;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background: #1c3d6c;
  color: #93bbf3;
}
/deep/.ant-collapse-content-box {
  border: 1px solid rgba(31, 54, 144, 0.7);
}
/deep/.ant-collapse {
  background: #1c3d6c !important;
}
/deep/.ant-collapse-item {
  margin-bottom: 0 !important;
  background: rgba(20, 34, 89, 0.7) !important;
}
.addData {
  padding: 8px 0;
  border: 1px solid #1c3d6c;
  border-top: 0;
  //   border-bottom: 0;
}
.ordertotalNum {
  border: 1px solid #1c3d6c;
  border-top: 0;
  height: 46px;
  span {
    width: 140px;
    text-align: center;
    color: #93bbf3;
    height: 46px;
    line-height: 46px;
  }
}
.totalNum {
  border: 1px solid #1c3d6c;
  border-top: 0;
  height: 46px;
  span {
    width: 215px;
    text-align: center;
    color: #93bbf3;
    height: 46px;
    line-height: 46px;
  }
}

.order-info {
  margin: 18px 12px 0 12px;
  padding: 0 10px;
}
.order-title {
  line-height: 40px;
  padding-bottom: 4px;
  border-bottom: 1px solid #2f538f;
  font-size: 15px;
  color: #529aff;
  margin-bottom: 8px;
  font-weight: bold;
}
.textarea,
.form-container {
  margin-top: 40px;
}
</style>